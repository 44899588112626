// import { graphql, useStaticQuery } from "gatsby"
import React from "react"
// import Assessment from "../shared/support_program/SupportProgram"

// const query = graphql`
//   {
//     allContentfulDoSupportProgram(sort: { fields: orderDesc, order: DESC }) {
//       edges {
//         node {
//           pdfFile {
//             file {
//               url
//             }
//           }
//           year
//           title
//         }
//       }
//     }
//   }
// `

const SupportProgramPage = () => {
//   const data = useStaticQuery(query)
//   const items = data.allContentfulDoSupportProgram.edges.map(item => {
//     const { url } = item.node.pdfFile.file
//     const { title } = item.node
//     if (url.slice(0, 2) === "//") {
//       return {
//         title,
//         path: `https:${url}`,
//       }
//     }
//     if (url.slice(0, 4) === "http") {
//       return {
//         title,
//         path: url,
//       }
//     }
//   })
  return (
    <div className="mt-12 md:mt-24">
      {/* <Assessment items={items} target="do" /> */}
      Coming Soon ...
    </div>
  )
}

export default SupportProgramPage
